import React from "react";
import ReactGoogleSlides from "react-google-slides";
import { Grid, Typography } from "@material-ui/core";
import { InputField, CheckboxField, SelectField } from "../FormFields";

const linkToPPTFile = "assets/TestSlideShow.pptx";

export default function App(props) {
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <ReactGoogleSlides
          width={"56%"}
          height={400}
          slidesLink="https://docs.google.com/presentation/d/1JZQ6sVvzNzUbrFvGvQh_ze4pJ4MgDRS7p7lLZ4Ch9bM/edit?usp=sharing"
          slideDuration={30}
          position={1}
          showControls
          loop
        />
      </div>
      <div style={{ textAlign: "right", paddingTop: "10px" }}>
        <hr />
      </div>
    </>
  );
}
