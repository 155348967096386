import React from 'react';
import { Typography } from '@material-ui/core/';

export default function Footer() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">      
      Powered by Kidgo 
        {/* <Link color="inherit" href="https://material-ui.com/"></Link> */}
        {/* {'Copyright © '}{new Date().getFullYear()} */}
    </Typography>
  );
}
