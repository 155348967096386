import * as Yup from "yup";
import welcomeFormModel from "./welcomeFormModel";

const {
  formField: { chkSlideShowWatched, preferredDayTime },
} = welcomeFormModel;

export default [
  Yup.object().shape({
    [chkSlideShowWatched.name]: Yup.bool().oneOf(
      [true],
      "Please indicate that you've watched the slideshow"
    ),
  }),
  Yup.object().shape({
    [preferredDayTime.name]: Yup.string().required(
      `${preferredDayTime.requiredErrorMsg}`
    ),
  }),
];
