import onboardingFormModel from "./onboardingFormModel";
const {
  formField: {
    chkInstructions,
    chkDocs,
    chkReady,

    chkSlideShowWatched,
    chkReviewSign,
    chkUpload,

    firstName,
    middleName,
    lastName,
    email,
    emailConfirm,
    phoneNumber,

    physicalAddress,
    physicalApt,
    physicalCity,
    physicalState,
    physicalZip,

    mailingAddressDifferent,
    mailingAddress,
    mailingApt,
    mailingCity,
    mailingState,
    mailingZip,

    emergencyFirstName,
    emergencyLastName,
    emergencyPhoneNumber,
    emergencyRelation,

    licenseNumber,
    licenseExpiryDate,

    ssn,
    ssnConfirm,
    birthDate,

    employerID,
    businessEntity,

    birthState,
    birthCountry,

    workerStatus,
    alienNumber,
    admissionNumber,
    alienAuthorizedDocType,
    alienAuthorizedExpiryDate,
    foreignPassportNumber,
    foreignPassportCountry,

    gender,
    heightFeet,
    heightInches,
    weight,
    hairColor,
    eyeColor,

    licensePlateNumber,
    licensePlateState,
    vin,
    registrationRenewalDate,

    vehicleYear,
    vehicleColor,
    vehicleMake,
    vehicleModel,

    vehicleCapacity,
    vehicleCarSeatCapable,
    vehicleClass,

    vehicleWCAccessible,
    vehicleWCType,
    vehicleWCQty,
    showWC,

    preferredDayTime,

    isSigned,
  },
} = onboardingFormModel;

export default {
  [chkInstructions.name]: false,
  [chkDocs.name]: false,
  [chkReady.name]: false,

  [chkSlideShowWatched.name]: false,
  [chkReviewSign.name]: false,
  [chkUpload.name]: false,

  [firstName.name]: "",
  [middleName.name]: "",
  [lastName.name]: "",
  [email.name]: "",
  [emailConfirm.name]: "",
  [phoneNumber.name]: "",

  [physicalAddress.name]: "",
  [physicalApt.name]: "",
  [physicalCity.name]: "",
  [physicalState.name]: "",
  [physicalZip.name]: "",

  [mailingAddressDifferent.name]: false,
  [mailingAddress.name]: "",
  [mailingApt.name]: "",
  [mailingCity.name]: "",
  [mailingState.name]: "",
  [mailingZip.name]: "",

  [emergencyFirstName.name]: "",
  [emergencyLastName.name]: "",
  [emergencyPhoneNumber.name]: "",
  [emergencyRelation.name]: "",

  [licenseNumber.name]: "",
  [licenseExpiryDate.name]: "",

  [ssn.name]: "",
  [ssnConfirm.name]: "",
  [birthDate.name]: "",

  [employerID.name]: "",
  [businessEntity.name]: "1",
  [businessEntity]: "1",

  [birthState.name]: "",
  [birthCountry.name]: "",

  [workerStatus.name]: "",
  [alienNumber.name]: "",
  [admissionNumber.name]: "",
  [alienAuthorizedDocType.name]: "",
  [alienAuthorizedExpiryDate.name]: "",
  [foreignPassportNumber.name]: "",
  [foreignPassportCountry.name]: "",

  [gender.name]: "",
  [heightFeet.name]: "",
  [heightInches.name]: "",
  [weight.name]: "",
  [hairColor.name]: "",
  [eyeColor.name]: "",

  [licensePlateNumber.name]: "",
  [licensePlateState.name]: "",
  [vin.name]: "",
  [registrationRenewalDate.name]: "",

  [vehicleYear.name]: "",
  [vehicleColor.name]: "",
  [vehicleMake.name]: "",
  [vehicleModel.name]: "",
  [isSigned.name]: "",

  [vehicleCapacity.name]: "",
  [vehicleCarSeatCapable.name]: "",
  [vehicleClass.name]: "",

  [vehicleWCAccessible.name]: "",
  [vehicleWCType.name]: "",
  [vehicleWCQty.name]: "",
  [showWC.name]: false,

  [preferredDayTime.name]: "",
};
