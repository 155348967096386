import React, { Suspense } from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MaterialLayout from "./components/Layout/MaterialLayout";
import GettingStarted from "./components/GettingStarted";
import Onboarding from "./components/Onboarding";
import CheckoutPage from "./components/CheckoutPage";
import Forms from "./components/Forms";
import RouteView from "./components/Route";

const GettingStartedPage = () => (
  <MaterialLayout>
    <Suspense fallback={<div>Loading</div>}>
      <GettingStarted />
    </Suspense>
  </MaterialLayout>
);
const Checkout = () => (
  <MaterialLayout>
    <Suspense fallback={<div>Loading</div>}>
      <CheckoutPage />
    </Suspense>
  </MaterialLayout>
);
const OnboardingPage = () => (
  <MaterialLayout>
    <Suspense fallback={<div>Loading</div>}>
      <Onboarding />
    </Suspense>
  </MaterialLayout>
);
const FormsPage = () => (
  <MaterialLayout>
    <Suspense fallback={<div>Loading</div>}>
      <Forms />
    </Suspense>
  </MaterialLayout>
);

const RouteViewPage = () => (
  <MaterialLayout>
    <Suspense fallback={<div>Loading</div>}>
      <RouteView />
    </Suspense>
  </MaterialLayout>
);

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<GettingStartedPage />} />
        </Routes>
        <Routes>
          <Route path="/GettingStarted" element={<GettingStartedPage />} />
        </Routes>
        <Routes>
          <Route path="/Onboarding" element={<OnboardingPage />} />
        </Routes>
        <Routes>
          <Route path="/Checkout" element={<Checkout />} />
        </Routes>
        <Routes>
          <Route path="/Forms" element={<FormsPage />} />
        </Routes>
        <Routes>
          <Route path="/Route" element={<RouteViewPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
