import React, { useState, useEffect } from "react";
import { dataRouteGroups, dataRouteOfferAccept } from "../api/data";
import RouteInfo from "./RouteInfo";
import Stops from "./Stops";
import Map from "./Map";
import TabPanel, { Item as TabItem } from "devextreme-react/tab-panel";
import { CheckBox } from "devextreme-react/check-box";
import { Button } from "devextreme-react/button";
import { notification } from "antd";

const Route = () => {
  const [group, setGroup] = useState("AM");
  const [hasAM, setHasAM] = useState(false);
  const [hasPM, setHasPM] = useState(false);
  const [hasER, setHasER] = useState(false);
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const [lock, setLock] = useState(false);

  const query = new URLSearchParams(window.location.search);
  const routeId = query.get("r");
  const uid = query.get("u");

  const props = { routeId: routeId, uid: uid, group: group };

  const dataSourceGroups = async () => {
    return await dataRouteGroups(props).then((data) => {
      setHasAM(data.hasAM);
      setHasPM(data.hasPM);
      setHasER(data.hasER);
    });
  };

  useEffect(() => {
    dataSourceGroups();
  }, []);

  const acceptOffer = () => {
    if (uid) {
      dataRouteOfferAccept(routeId, uid).then((data) => {
        if (data.error) {
          notification.open({
            message: "",
            description: "Sorry, this offer is no longer available.",
            style: { backgroundColor: "#f7d7d5" },
            duration: 3.5,
          });
          setLock(true);
        } else {
          notification.open({
            message: "",
            description: "This offer was successfully accepted.",
            style: { backgroundColor: "#edfaf2" },
            duration: 3.5,
          });
          setLock(true);
        }
      });
    }
  };

  return (
    <>
      <div style={{ marginBottom: "10px" }}>
        <RouteInfo props={props} />
      </div>
      <div style={{ margin: "10px" }}>
        <TabPanel
          deferRendering={true}
          onSelectionChanged={(e) => {
            setGroup(e.addedItems[0].title);
          }}
        >
          {hasAM && <TabItem title="AM"></TabItem>}
          {hasPM && <TabItem title="PM"></TabItem>}
          {hasER && <TabItem title="Early Release"></TabItem>}
        </TabPanel>
        <Stops props={props} />
      </div>
      <div style={{ margin: "10px" }}>
        {/* <Map props={propsMap} /> */}
        <Map props={props} />
      </div>
      <div style={{ margin: "10px", marginTop: "30px" }}>
        <CheckBox
          text="I have carefully reviewed the route details including the required equipment and I am ready to accept."
          onValueChanged={(e) => {
            setHasConfirmed(e.value);
          }}
        ></CheckBox>
      </div>
      <div style={{ margin: "10px", marginTop: "30px" }}>
        <Button
          text="Accept Route"
          width="100%"
          icon="check"
          type="success"
          disabled={!hasConfirmed || lock}
          onClick={() => acceptOffer()}
        ></Button>
      </div>
    </>
  );
};

export default Route;
