import React, { useEffect, useState } from "react";
import { dataRoute } from "../api/data";
import "devextreme-react/tag-box";
import "devextreme-react/text-area";
import {
  Form,
  SimpleItem,
  Item,
  Label,
  GroupItem,
} from "devextreme-react/form";

const RouteInfo = (props) => {
  const [routeProps, setRouteProps] = useState({});
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setRouteProps({
      routeId: props.props.routeId,
      uid: props.props.uid,
    });
  }, [props.props.routeId, props.props.uid]);

  useEffect(async () => {
    if (routeProps.routeId) {
      setFormData(await dataRoute(routeProps));
    }
  }, [routeProps]);

  return (
    <>
      <Form
        id="form"
        readOnly={true}
        formData={formData}
        style={{ padding: "9px", textAlign: "left" }}
        labelMode="floating"
      >
        <SimpleItem dataField="routeName" />
        <SimpleItem dataField="routeStatus">
          <Label text="Status" />
        </SimpleItem>
        <SimpleItem dataField="county" />
        <SimpleItem dataField="pay" />
        <SimpleItem
          dataField="equipment"
          editorType="dxTagBox"
          editorOptions={{ readOnly: "true", showClearButton: "false" }}
        />
        <SimpleItem
          dataField="notes"
          editorType="dxTextArea"
          editorOptions={{ autoResizeEnabled: "true" }}
        />
      </Form>
    </>
  );
};

export default RouteInfo;
