import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import firebaseConfig from "../config/FirebaseConfig";

export const app = firebase.initializeApp(firebaseConfig);

if (window.location.hostname === "localhost") {
  app.functions().useEmulator("localhost", 5001);
  //app.storage().useEmulator("localhost", 9199);
  app.auth().useEmulator("http://localhost:9099");
  app.firestore().settings({
    host: "localhost:8080",
    ssl: false,
  });
}

// firebase utils
const db = firebase.firestore();
const auth = firebase.auth();
const currentUser = auth.currentUser;
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const storage = new firebase.storage();

export {
  db,
  auth,
  currentUser,
  googleAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  githubAuthProvider,
  storage,
};
