import React, { useEffect } from "react";
import { dataRouteStops } from "../api/data";
import {
  DataGrid,
  ColumnFixing,
  Column,
  Scrolling,
  Sorting,
} from "devextreme-react/data-grid";

const Stops = (props) => {
  const routeStopProps = {
    routeId: props.props.routeId,
    uid: props.props.uid,
    group: props.props.group,
  };
  const dataSourceStops = dataRouteStops(routeStopProps);

  return (
    <>
      <DataGrid
        className={"dx-card wide-card"}
        dataSource={dataSourceStops}
        showBorders={true}
        remoteOperations={false}
        allowColumnReordering={false}
        allowColumnResizing={false}
        columnHidingEnabled={true}
        wordWrapEnabled={true}
        rowAlternationEnabled={true}
        columnMinWidth={50}
        repaintChangesOnly={true}
        disabled={false}
      >
        <ColumnFixing enabled={true} />
        <Scrolling mode="virtual" />
        <Sorting mode="none" />
        <Column
          dataField="stopOrder"
          caption="Stop"
          dataType="string"
          allowHiding={false}
          allowReordering={false}
          width={50}
        />

        <Column
          dataField="time"
          dataType="string"
          allowHiding={false}
          allowReordering={false}
          width={80}
        ></Column>

        <Column
          dataField="address"
          dataType="string"
          allowHiding={false}
          allowReordering={false}
        />

        <Column
          dataField="client"
          caption="Passenger"
          dataType="string"
          allowHiding={false}
          allowReordering={false}
        />

        <Column
          dataField="notes"
          dataType="string"
          allowHiding={false}
          allowReordering={false}
        />

        <Column
          dataField="group"
          dataType="string"
          allowHiding={false}
          allowReordering={false}
          visible={false}
        />
      </DataGrid>
    </>
  );
};

export default Stops;
