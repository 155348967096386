import React from "react";
export const useLocalStorageState = ({ key, value }) => {
  const parsedLocalStorage = JSON.parse(localStorage.getItem(key) || "{}");
  const initialValue =
    Object.keys(parsedLocalStorage).length > 0 ? parsedLocalStorage : value;
  const [localStorageState, setLocalStorageState] =
    React.useState(initialValue);
  const handleUpdateLocalStorageState = React.useCallback(
    (x) => {
      setLocalStorageState(x);
      localStorage.setItem(key, JSON.stringify(x));

      const savedValues = JSON.parse(localStorage.getItem(key));
      const firstName = !savedValues.firstName ? "" : savedValues.firstName;
      const lastName = !savedValues.lastName ? "" : savedValues.lastName;

      savedValues.fullName = firstName + " " + lastName;

      localStorage.setItem(key, JSON.stringify(savedValues));
    },
    [key]
  );
  return [localStorageState, handleUpdateLocalStorageState];
};
