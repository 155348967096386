import React, { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import Input from "./input";
import Welcome from "./welcome";
import PDFViewer from "./PDFViewer";
import useStyles from "./styles";

const steps = ["Welcome", "Driver Info", "Review & Sign", "Next Steps"];

function _renderStepContent(step) {
  switch (step) {
    case 0:
      return <Welcome />;
    case 1:
      return <Input />;
    case 2:
      return <PDFViewer />;
    default:
      return <div>[Description of next steps, etc]</div>;
  }
}

export default function Forms() {
  const classes = useStyles();
  const savedLastStep_Forms = !localStorage.getItem("savedLastStep_Forms")
    ? 0
    : localStorage.getItem("savedLastStep_Forms");

  const [activeStep, setActiveStep] = useState(parseInt(savedLastStep_Forms));

  const isLastStep = activeStep === steps.length - 1;

  function _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    await _sleep(1000);
    alert(JSON.stringify(values, null, 2));
    actions.setSubmitting(false);

    setActiveStep(activeStep + 1);
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      localStorage.setItem("savedLastStep_Forms", activeStep + 1);
      //actions.setTouched({});
      //localStorage.setItemactions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4" align="center">
        Driver Registration
      </Typography>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {_renderStepContent(activeStep)}

      <div className={classes.buttons}>
        {activeStep !== 0 && (
          <Button onClick={_handleBack} className={classes.button}>
            Back
          </Button>
        )}
        <div className={classes.wrapper}>
          <Button
            disabled={activeStep == 2}
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={_handleSubmit}
          >
            {isLastStep ? "Finished" : "Next"}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}
