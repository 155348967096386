import * as Yup from "yup";
import onboardingFormModel from "./onboardingFormModel";

const {
  formField: {
    chkInstructions,
    chkDocs,
    chkReady,

    chkReviewSign,

    firstName,
    lastName,
    email,
    emailConfirm,
    phoneNumber,

    physicalAddress,

    physicalCity,
    physicalState,
    physicalZip,

    mailingZip,

    emergencyFirstName,
    emergencyLastName,
    emergencyPhoneNumber,
    emergencyRelation,

    licenseNumber,
    licenseExpiryDate,

    ssn,
    ssnConfirm,

    employerID,
    businessEntity,

    birthDate,

    birthCountry,

    workerStatus,
    alienNumber,
    alienAuthorizedDocType,
    admissionNumber,
    foreignPassportNumber,
    foreignPassportCountry,

    gender,
    heightFeet,
    heightInches,
    weight,
    hairColor,
    eyeColor,

    licensePlateNumber,
    licensePlateState,
    vin,
    registrationRenewalDate,

    vehicleYear,
    vehicleColor,
    vehicleMake,
    vehicleModel,

    vehicleCapacity,
    vehicleCarSeatCapable,
    vehicleClass,

    vehicleWCAccessible,
    vehicleWCType,
    vehicleWCQty,
    showWC,

    preferredDayTime,

    isSigned,
  },
} = onboardingFormModel;

const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
const zipRegExp = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
const ssnRegExp = /^(\d{3}-?\d{2}-?\d{4}|XXX-XX-XXXX)$/;
const einRegExp = /^\d{2}\-?\d{7}$/;

export default [
  // Yup.object().shape({
  //   [chkSlideShowWatched.name]: Yup.bool().oneOf(
  //     [true],
  //     "Please indicate that you've watched the slideshow"
  //   ),
  // }),
  Yup.object().shape({
    [isSigned.name]: Yup.string().test(
      "is-signed",
      "Please sign",
      (value) => value === "signed"
    ),

    [chkInstructions.name]: Yup.bool().oneOf([true], "Please indicate"),
    [chkDocs.name]: Yup.bool().oneOf([true], "Please indicate"),
    [chkReady.name]: Yup.bool().oneOf([true], "Please indicate"),

    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
    [email.name]: Yup.string().required(`${email.requiredErrorMsg}`),
    [emailConfirm.name]: Yup.string()
      .required(`${emailConfirm.requiredErrorMsg}`)
      .when("email", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("email")],
          "Both emails need to be the same"
        ),
      }),
    [physicalAddress.name]: Yup.string().required(
      `${physicalAddress.requiredErrorMsg}`
    ),
    [phoneNumber.name]: Yup.string()
      .required(`${phoneNumber.requiredErrorMsg}`)
      .matches(phoneRegExp, "Phone number is not valid"),

    [physicalCity.name]: Yup.string().required(
      `${physicalCity.requiredErrorMsg}`
    ),

    [physicalState.name]: Yup.string().required(
      `${physicalState.requiredErrorMsg}`
    ),

    [physicalZip.name]: Yup.string()
      .required(`${physicalZip.requiredErrorMsg}`)
      .matches(zipRegExp, "Postal code is not valid"),

    [mailingZip.name]: Yup.string().matches(
      zipRegExp,
      "Postal code is not valid"
    ),

    [emergencyFirstName.name]: Yup.string().required(
      `${emergencyFirstName.requiredErrorMsg}`
    ),

    [emergencyLastName.name]: Yup.string().required(
      `${emergencyLastName.requiredErrorMsg}`
    ),

    [emergencyPhoneNumber.name]: Yup.string()
      .required(`${emergencyPhoneNumber.requiredErrorMsg}`)
      .matches(phoneRegExp, "Phone number is not valid"),

    [emergencyRelation.name]: Yup.string().required(
      `${emergencyRelation.requiredErrorMsg}`
    ),

    [licenseNumber.name]: Yup.string().required(
      `${licenseNumber.requiredErrorMsg}`
    ),

    [licenseExpiryDate.name]: Yup.string().required(
      `${licenseExpiryDate.requiredErrorMsg}`
    ),

    // irs
    [ssn.name]: Yup.string()
      .required(`${ssn.requiredErrorMsg}`)
      .matches(ssnRegExp, "SSN is not valid"),

    [ssnConfirm.name]: Yup.string()
      .required(`${ssnConfirm.requiredErrorMsg}`)
      .matches(ssnRegExp, "SSN is not valid")
      .when("ssn", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("ssn")],
          "The SSNs need to be the same"
        ),
      }),

    [businessEntity.name]: Yup.string().required(
      `${businessEntity.requiredErrorMsg}`
    ),

    [employerID.name]: Yup.string()
      .matches(einRegExp, "Employer ID is not valid")
      .when("businessEntity", {
        is: "2",
        then: Yup.string().required("Required"),
      })
      .when("businessEntity", {
        is: "3",
        then: Yup.string().required("Required"),
      })
      .when("businessEntity", {
        is: "4",
        then: Yup.string().required("Required"),
      })
      .when("businessEntity", {
        is: "5",
        then: Yup.string().required("Required"),
      })
      .when("businessEntity", {
        is: "6",
        then: Yup.string().required("Required"),
      }),

    [workerStatus.name]: Yup.string().required(
      `${workerStatus.requiredErrorMsg}`
    ),

    [alienAuthorizedDocType.name]: Yup.string().when("workerStatus", {
      is: "4",
      then: Yup.string().required("Required"),
    }),

    [alienNumber.name]: Yup.string()
      .when("workerStatus", {
        is: "3",
        then: Yup.string().required("Required"),
      })
      .when("alienAuthorizedDocType", {
        is: "1",
        then: Yup.string().required("Required"),
      }),

    [admissionNumber.name]: Yup.string().when("alienAuthorizedDocType", {
      is: "2",
      then: Yup.string().required("Required"),
    }),

    [foreignPassportNumber.name]: Yup.string().when("alienAuthorizedDocType", {
      is: "3",
      then: Yup.string().required("Required"),
    }),

    [foreignPassportCountry.name]: Yup.string().when("alienAuthorizedDocType", {
      is: "3",
      then: Yup.string().required("Required"),
    }),

    // irs-end

    [birthDate.name]: Yup.string().required(`${birthDate.requiredErrorMsg}`),

    [birthCountry.name]: Yup.string().required(
      `${birthCountry.requiredErrorMsg}`
    ),

    [gender.name]: Yup.string().required(`${gender.requiredErrorMsg}`),

    [heightFeet.name]: Yup.number()
      .required(`${heightFeet.requiredErrorMsg}`)
      .typeError("Not a valid number"),

    [heightInches.name]: Yup.number()
      .required(`${heightInches.requiredErrorMsg}`)
      .typeError("Not a valid number"),

    [weight.name]: Yup.number()
      .required(`${weight.requiredErrorMsg}`)
      .typeError("Not a valid number"),

    [hairColor.name]: Yup.string().required(`${hairColor.requiredErrorMsg}`),

    [eyeColor.name]: Yup.string().required(`${eyeColor.requiredErrorMsg}`),

    [licensePlateNumber.name]: Yup.string().required(
      `${licensePlateNumber.requiredErrorMsg}`
    ),
    [licensePlateState.name]: Yup.string().required(
      `${licensePlateState.requiredErrorMsg}`
    ),
    [vin.name]: Yup.string().required(`${vin.requiredErrorMsg}`),
    [registrationRenewalDate.name]: Yup.string().required(
      `${registrationRenewalDate.requiredErrorMsg}`
    ),

    [vehicleYear.name]: Yup.number()
      .typeError("Not a valid year")
      .required(`${vehicleYear.requiredErrorMsg}`)
      .min(2013, "Must be 2013 or newer")
      .max(2030, "Not a valid year"),

    [vehicleColor.name]: Yup.string().required(
      `${vehicleColor.requiredErrorMsg}`
    ),
    [vehicleMake.name]: Yup.string().required(
      `${vehicleMake.requiredErrorMsg}`
    ),
    [vehicleModel.name]: Yup.string().required(
      `${vehicleModel.requiredErrorMsg}`
    ),

    [vehicleCapacity.name]: Yup.number()
      .required(`${vehicleCapacity.requiredErrorMsg}`)
      .typeError("Please enter a number"),

    [vehicleCarSeatCapable.name]: Yup.string().required(
      `${vehicleCarSeatCapable.requiredErrorMsg}`
    ),
    [vehicleClass.name]: Yup.string().required(
      `${vehicleClass.requiredErrorMsg}`
    ),

    [vehicleWCAccessible.name]: Yup.string().required(
      `${vehicleWCAccessible.requiredErrorMsg}`
    ),

    [showWC.name]: Yup.boolean(),

    [vehicleWCType.name]: Yup.string().when("showWC", {
      is: true,
      then: Yup.string().required("Required"),
    }),

    [vehicleWCQty.name]: Yup.number().when("showWC", {
      is: true,
      then: Yup.number()
        .required("Required")
        .typeError("Please enter a number"),
    }),

    [preferredDayTime.name]: Yup.string().required(
      `${preferredDayTime.requiredErrorMsg}`
    ),
  }),
  Yup.object().shape({
    [chkReviewSign.name]: Yup.bool().oneOf(
      [true],
      "Please indicate that you've reviewed the document"
    ),
  }),
  // Yup.object().shape({
  //   [chkUpload.name]: Yup.bool().oneOf(
  //     [true],
  //     "Please indicate that you've uploaded all required documents"
  //   ),
  // }),
];
