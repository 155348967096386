import React from "react";
import MaskedInput from "react-text-mask";
import { Grid, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Formik, Form, ErrorMessage } from "formik";
import { object, string, ref } from "yup";
import { InputField } from "../FormFields";
import { useLocalStorageState } from "../hook";

const LOCAL_STORAGE_KEY = "customLocalStorageKey";

const INITIAL_VALUES = { foo: "", bar: [] };

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        "-",
        /[1-9]/,
        /[1-9]/,
        "-",
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}
const MyForm = ({ saveForm, ...props }) => {
  React.useEffect(() => {
    saveForm(props.values);
  }, [props.values, saveForm]);

  const RegisterValidation = object().shape({
    lastName: string().required("Required"),
    email: string()
      .required("Valid email required")
      .email("Valid email required"),
  });

  return (
    <Form>
      <div style={{ margin: "20px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <InputField name="firstName" label="First Name" fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField name="middleInitial" label="Middle Initial" fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField name="lastName" label="Last Name" fullWidth />
            <ErrorMessage
              name="lastName"
              component="div"
              className="text-red-500 text-xs"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <InputField name="mobilePhone" label="Mobile Phone" fullWidth />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputField name="homePhone" label="Home Phone" fullWidth />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputField name="workPhone" label="Work Phone" fullWidth />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputField name="phoneExtension" label="Extension" fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <InputField name="email" label="Email" fullWidth />
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputField name="emailConfirm" label="Confirm Email" fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <InputField
              name="physicalAddress1"
              label="Physical Address 1"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField
              name="physicalAddress2"
              label="Physical Address 2"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField name="physicalCity" label="Physical City" fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <InputField name="physicalState" label="Physical State" fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField name="physicalZip" label="Physical Zip" fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField
              name="mailingAddressSame"
              label="Mailing Address Same"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <InputField
              name="mailingAddress1"
              label="Mailing Address 1"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField
              name="mailingAddress2"
              label="Mailing Address 2"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField name="mailingCity" label="Mailing City" fullWidth />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <InputField name="mailingState" label="Mailing State" fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField name="mailingZip" label="Mailing Zip" fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputField
              name="licenseNumber"
              label="Driver License Number"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              name="licenseState"
              label="Driver License State"
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <InputField
              name="licenseExpirationMonth"
              label="Expiration Month"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField
              name="licenseExpirationDay"
              label="Expiration Day"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField
              name="licenseExpirationYear"
              label="Expiration Year"
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <InputField name="dobMonth" label="Date of Birth Month" fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField name="dobDay" label="Date of Birth Day" fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField name="dobYear" label="Date of Birth Year" fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <InputField
              name="ssn"
              label="SSN"
              InputProps={{
                inputComponent: TextMaskCustom,
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputField name="hairColor" label="Hair Color" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField name="eyeColor" label="Eye Color" fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputField name="heightFeet" label="Height (Feet)" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField name="heightInches" label="Height (Inches)" fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputField name="weight" label="Weight (Pounds)" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField name="gender" label="Gender" fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputField
              name="emergencyContactFirstName"
              label="Emergency Contact First Name"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              name="emergencyContactLastName"
              label="Emergency Contact Last Name"
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputField
              name="emergencyContactPhone"
              label="Emergency Contact Phone"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              name="emergencyContactPhoneExtension"
              label="Emergency Contact Phone Extension"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              name="emergencyContactRelation"
              label="Emergency Contact Relation"
              fullWidth
            />
          </Grid>
        </Grid>
      </div>
    </Form>
  );
};

function App() {
  const [initialValues, handleUpdateForm] = useLocalStorageState({
    key: LOCAL_STORAGE_KEY,
    value: INITIAL_VALUES,
  });

  return (
    <div className="App">
      <Formik enableReinitialize initialValues={initialValues}>
        {(props) => <MyForm saveForm={handleUpdateForm} {...props} />}
      </Formik>
    </div>
  );
}

export default App;
