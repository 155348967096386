export default {
  formId: "welcomeForm",
  formField: {
    chkSlideShowWatched: {
      name: "chkSlideShowWatched",
      label: "I have watched the slideshow",
      requiredErrorMsg: "Please indicate that you've watched the slideshow",
    },
    preferredDayTime: {
      name: "preferredDayTime",
      label: "Preferred Day/Time",
      requiredErrorMsg: "Required",
    },
    chkMondayAm: {
      name: "chkMondayAm",
      label: "",
      requiredErrorMsg: "",
    },
    chkMondayPm: {
      name: "chkMondayPm",
      label: "",
      requiredErrorMsg: "",
    },
    chkTuesdayAm: {
      name: "chkTuesdayAm",
      label: "",
      requiredErrorMsg: "",
    },
    chkTuesdayPm: {
      name: "chkTuesdayPm",
      label: "",
      requiredErrorMsg: "",
    },
    chkWednesdayAm: {
      name: "chkWednesdayAm",
      label: "",
      requiredErrorMsg: "",
    },
    chkWednesdayPm: {
      name: "chkWednesdayPm",
      label: "",
      requiredErrorMsg: "",
    },
    chkThursdayAm: {
      name: "chkThursdayAm",
      label: "",
      requiredErrorMsg: "",
    },
    chkThursdayPm: {
      name: "chkThursdayPm",
      label: "",
      requiredErrorMsg: "",
    },
    chkFridayAm: {
      name: "chkFridayAm",
      label: "",
      requiredErrorMsg: "",
    },
    chkFridayPm: {
      name: "chkFridayPm",
      label: "",
      requiredErrorMsg: "",
    },
  },
};
