export default {
  formId: "onboardingForm",
  formField: {
    chkInstructions: {
      name: "chkInstructions",
      label: "I have reviewed all the instructions provided",
      requiredErrorMsg: "Please indicate",
    },

    chkDocs: {
      name: "chkDocs",
      label:
        "I have gathered all the documents that I will need to complete the application",
      requiredErrorMsg: "Please indicate",
    },

    chkReady: {
      name: "chkReady",
      label: "I am ready to complete and sign all forms",
      requiredErrorMsg: "Please indicate",
    },

    chkSlideShowWatched: {
      name: "chkSlideShowWatched",
      label: "I have watched the slideshow",
      requiredErrorMsg: "Please indicate that you've watched the slideshow",
    },

    chkReviewSign: {
      name: "chkReviewSign",
      label: "I have reviewed the above document",
      requiredErrorMsg: "Please indicate that you've reviewed the document",
    },

    chkUpload: {
      name: "chkUpload",
      label: "I have uploaded all required documents",
      requiredErrorMsg:
        "Please indicate that you've uploaded all required documents",
    },

    firstName: {
      name: "firstName",
      label: "Driver First Name",
      requiredErrorMsg: "Required",
    },

    middleName: {
      name: "middleName",
      label: "Driver Middle Name",
      requiredErrorMsg: "Required",
    },

    lastName: {
      name: "lastName",
      label: "Driver Last Name",
      requiredErrorMsg: "Required",
    },

    email: {
      name: "email",
      label: "Email",
      requiredErrorMsg: "Required",
    },

    emailConfirm: {
      name: "emailConfirm",
      label: "Confirm Email",
      requiredErrorMsg: "Required",
    },

    phoneNumber: {
      name: "phoneNumber",
      label: "Phone Number",
      requiredErrorMsg: "Required",
    },

    physicalAddress: {
      name: "physicalAddress",
      label: "Address",
      requiredErrorMsg: "Required",
    },
    physicalApt: {
      name: "physicalApt",
      label: "Apartment Number",
      requiredErrorMsg: "Required",
    },
    physicalCity: {
      name: "physicalCity",
      label: "City",
      requiredErrorMsg: "Required",
    },
    physicalState: {
      name: "physicalState",
      label: "State",
      requiredErrorMsg: "Required",
    },
    physicalZip: {
      name: "physicalZip",
      label: "Postal Code",
      requiredErrorMsg: "Required",
    },

    mailingAddressDifferent: {
      name: "mailingAddressDifferent",
      label: "I have a different mailing address",
      requiredErrorMsg: "Required",
    },

    mailingAddress: {
      name: "mailingAddress",
      label: "Address",
      requiredErrorMsg: "Required",
    },
    mailingApt: {
      name: "mailingApt",
      label: "Apartment Number",
      requiredErrorMsg: "Required",
    },
    mailingCity: {
      name: "mailingCity",
      label: "City",
      requiredErrorMsg: "Required",
    },
    mailingState: {
      name: "mailingState",
      label: "State",
      requiredErrorMsg: "Required",
    },
    mailingZip: {
      name: "mailingZip",
      label: "Postal Code",
      requiredErrorMsg: "Required",
    },

    emergencyFirstName: {
      name: "emergencyFirstName",
      label: "First Name",
      requiredErrorMsg: "Required",
    },
    emergencyLastName: {
      name: "emergencyLastName",
      label: "Last Name",
      requiredErrorMsg: "Required",
    },
    emergencyPhoneNumber: {
      name: "emergencyPhoneNumber",
      label: "Phone Number",
      requiredErrorMsg: "Required",
    },

    emergencyRelation: {
      name: "emergencyRelation",
      label: "Relation",
      requiredErrorMsg: "Required",
    },

    licenseNumber: {
      name: "licenseNumber",
      label: "Driver's License Number",
      labelMonitor: "Government Identification Card",
      requiredErrorMsg: "Required",
    },

    licenseExpiryDate: {
      name: "licenseExpiryDate",
      label: "Driver's License Expiry Date",
      labelMonitor: "Government Identification Card Expiry Date",
      requiredErrorMsg: "Required",
    },

    ssn: {
      name: "ssn",
      label: "Social Security Number",
      requiredErrorMsg: "Required",
    },

    ssnConfirm: {
      name: "ssnConfirm",
      label: "Confirm Social Security Number",
      requiredErrorMsg: "Required",
    },

    birthDate: {
      name: "birthDate",
      label: "Date of Birth",
      requiredErrorMsg: "Required",
    },

    employerID: {
      name: "employerID",
      label: "Employee ID",
      requiredErrorMsg: "Required",
    },

    businessEntity: {
      name: "businessEntity",
      label: "Business Entity",
      requiredErrorMsg: "Required",
    },

    birthState: {
      name: "birthState",
      label: "State of Birth",
      requiredErrorMsg: "Required",
    },

    birthCountry: {
      name: "birthCountry",
      label: "Country of Birth",
      requiredErrorMsg: "Required",
    },

    // IRS Form

    workerStatus: {
      name: "workerStatus",
      label: "I attest that I am",
      requiredErrorMsg: "Required",
    },

    alienNumber: {
      name: "alienNumber",
      label: "Alien Number",
      requiredErrorMsg: "Required",
    },

    alienAuthorizedDocType: {
      name: "alienAuthorizedDocType",
      label: "I have one of the following",
      requiredErrorMsg: "Required",
    },

    alienAuthorizedExpiryDate: {
      name: "alienAuthorizedExpiryDate",
      label: "Authorization Expiry (if applicable)",
      requiredErrorMsg: "Required",
    },

    admissionNumber: {
      name: "admissionNumber",
      label: "Form I-94 Admission Number",
      requiredErrorMsg: "Required",
    },

    foreignPassportNumber: {
      name: "foreignPassportNumber",
      label: "Foreign Passport Number",
      requiredErrorMsg: "Required",
    },

    foreignPassportCountry: {
      name: "foreignPassportCountry",
      label: "Foreign Passport Country",
      requiredErrorMsg: "Required",
    },

    // IRS Form - End

    gender: {
      name: "gender",
      label: "Legal Gender",
      requiredErrorMsg: "Required",
    },
    heightFeet: {
      name: "heightFeet",
      label: "Height (Feet)",
      requiredErrorMsg: "Required",
    },
    heightInches: {
      name: "heightInches",
      label: "Height (Inches)",
      requiredErrorMsg: "Required",
    },
    weight: {
      name: "weight",
      label: "Weight (Pounds)",
      requiredErrorMsg: "Required",
    },
    hairColor: {
      name: "hairColor",
      label: "Hair Color",
      requiredErrorMsg: "Required",
    },
    eyeColor: {
      name: "eyeColor",
      label: "Eye Color",
      requiredErrorMsg: "Required",
    },

    licensePlateNumber: {
      name: "licensePlateNumber",
      label: "License Plate Number",
      requiredErrorMsg: "Required",
    },
    licensePlateState: {
      name: "licensePlateState",
      label: "License Plate State",
      requiredErrorMsg: "Required",
    },
    vin: {
      name: "vin",
      label: "VIN",
      requiredErrorMsg: "Required",
    },
    registrationRenewalDate: {
      name: "registrationRenewalDate",
      label: "Registration Expiry",
      requiredErrorMsg: "Required",
    },

    vehicleYear: {
      name: "vehicleYear",
      label: "Year",
      requiredErrorMsg: "Required",
    },
    vehicleColor: {
      name: "vehicleColor",
      label: "Color",
      requiredErrorMsg: "Required",
    },
    vehicleMake: {
      name: "vehicleMake",
      label: "Make",
      requiredErrorMsg: "Required",
    },
    vehicleModel: {
      name: "vehicleModel",
      label: "Model",
      requiredErrorMsg: "Required",
    },

    vehicleCapacity: {
      name: "vehicleCapacity",
      label: "Number of seatbelts (excluding driver's seat)",
      requiredErrorMsg: "Required",
    },
    vehicleCarSeatCapable: {
      name: "vehicleCarSeatCapable",
      label: "Car Seat Capable",
      requiredErrorMsg: "Required",
    },
    vehicleClass: {
      name: "vehicleClass",
      label: "Class",
      requiredErrorMsg: "Required",
    },

    vehicleWCType: {
      name: "vehicleWCType",
      label: "Wheelchair Van Type",
      requiredErrorMsg: "Required",
    },

    vehicleWCQty: {
      name: "vehicleWCQty",
      label: "How many wheelchairs can this vehicle accomodate?",
      requiredErrorMsg: "Required",
    },

    showWC: {
      name: "showWC",
      label: "",
      requiredErrorMsg: "Required",
    },

    vehicleWCAccessible: {
      name: "vehicleWCAccessible",
      label: "Wheelchair Accessible",
      requiredErrorMsg: "Required",
    },

    preferredDayTime: {
      name: "preferredDayTime",
      label: "Preferred Day/Time",
      requiredErrorMsg: "Required",
    },

    isSigned: {
      name: "isSigned",
      label: "",
      requiredErrorMsg: "Please sign",
    },
  },
};
