import React, { useState, useEffect, useCallback } from "react";
import { dataRouteTrip } from "../api/data";
import {
  GoogleMap,
  useJsApiLoader,
  DirectionsRenderer,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: 39.8283,
  lng: -98.5795,
}; // central US

const Map = (props) => {
  const [tripProps, setTripProps] = useState({});
  const [tripData, setTripData] = useState();

  useEffect(() => {
    if (props.props.routeId) {
      setTripProps({
        routeId: props.props.routeId,
        uid: props.props.uid,
        group: props.props.group,
      });
    }
  }, [props.props.routeId, props.props.group]);

  useEffect(async () => {
    if (tripProps) {
      setTripData(await dataRouteTrip(tripProps));
    }
  }, [tripProps]);

  useEffect(() => {
    try {
      const directionsService = new window.google.maps.DirectionsService();
      let wayPoints = [];
      if (tripData.wayPoints) {
        wayPoints.push(...tripData.wayPoints);
      }

      const routeData = {
        origin: tripData.origin,
        destination: tripData.destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
        waypoints: wayPoints,
      };

      directionsService.route(routeData, (result, status) => {
        console.log("result", result);
        const directionsRenderer = new window.google.maps.DirectionsRenderer();
        directionsRenderer.setDirections(result);

        directionsRenderer.setOptions({
          polylineOptions: {
            strokeColor: "dodgerblue",
            strokeWeight: 5,
            strokeOpacity: 0.8,
          },
        });
        directionsRenderer.setMap(map);
      });
    } catch (e) {}
  }, [tripData]);

  const [map, setMap] = useState(null);

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    map.setZoom(5);
    setMap(map);

    const directionsService = new window.google.maps.DirectionsService();

    let tripStartPoint = "";
    let tripEndPoint = "";

    const routeData = {
      origin: tripStartPoint,
      destination: tripEndPoint,
      travelMode: window.google.maps.TravelMode.DRIVING,
    };

    directionsService.route(routeData, (result, status) => {
      console.log("result", result);
      const directionsRenderer = new window.google.maps.DirectionsRenderer();
      directionsRenderer.setDirections(result);

      directionsRenderer.setOptions({
        polylineOptions: {
          strokeColor: "dodgerblue",
          strokeWeight: 5,
          strokeOpacity: 0.8,
        },
      });
      directionsRenderer.setMap(map);
    });
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCvmTFw44St6MoaLcEOVHQVoPzJofcnnhA",
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{ minZoom: 4, maxZoom: 16 }}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default Map;
