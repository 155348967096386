import React, { useEffect, useRef } from "react";
import WebViewer from "@pdftron/webviewer";

export default function App(props) {
  const viewer = useRef(null);

  useEffect(() => {
    WebViewer(
      {
        path: "webviewer",
        initialDoc: "assets/ITG_MASTER_v2.pdf",
      },
      viewer.current
    ).then((instance) => {
      const { documentViewer, annotationManager, Annotations } = instance.Core;
      var FitMode = instance.UI.FitMode;
      instance.UI.setFitMode(FitMode.FitWidth);

      const savedValues = JSON.parse(
        localStorage.getItem("customLocalStorageKey")
      );

      documentViewer.addEventListener("documentLoaded", () => {
        documentViewer.getAnnotationsLoadedPromise().then(() => {
          const fieldManager = annotationManager.getFieldManager();

          const fullName1 = fieldManager.getField("FullName1");
          fullName1.setValue(savedValues.fullName);

          const fullName2 = fieldManager.getField("FullName2");
          fullName2.setValue(savedValues.fullName);

          const fullName3 = fieldManager.getField("FullName3");
          fullName3.setValue(savedValues.fullName);

          const fullName4 = fieldManager.getField("FullName4");
          fullName4.setValue(savedValues.fullName);

          const fullName5 = fieldManager.getField("FullName5");
          fullName5.setValue(savedValues.fullName);

          const fullName6 = fieldManager.getField("FullName6");
          fullName6.setValue(savedValues.fullName);

          const phoneNumber = fieldManager.getField("PhoneNumber");
          phoneNumber.setValue(savedValues.mobilePhone);

          const phoneExtension = fieldManager.getField("PhoneExtension");
          phoneExtension.setValue(savedValues.phoneExtension);

          var today = new Date();
          var dd = String(today.getDate()).padStart(2, "0");
          var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
          var yyyy = today.getFullYear();

          today = mm + "/" + dd + "/" + yyyy;

          const todaysDate1 = fieldManager.getField("Today1");
          todaysDate1.setValue(today);
          const todaysDate2 = fieldManager.getField("Today2");
          todaysDate2.setValue(today);
          const todaysDate3 = fieldManager.getField("Today3");
          todaysDate3.setValue(today);
          const todaysDate4 = fieldManager.getField("Today4");
          todaysDate4.setValue(today);
          const todaysDate5 = fieldManager.getField("Today5");
          todaysDate5.setValue(today);
          const todaysDate6 = fieldManager.getField("Today6");
          todaysDate6.setValue(today);

          const email = fieldManager.getField("Email");
          email.setValue(savedValues.email);

          const physicalAddress1 = fieldManager.getField("PhysicalAddress1");
          physicalAddress1.setValue(savedValues.physicalAddress1);

          const physicalAddress2 = fieldManager.getField("PhysicalAddress2");
          physicalAddress2.setValue(savedValues.physicalAddress2);

          const physicalCity = fieldManager.getField("PhysicalCity");
          physicalCity.setValue(savedValues.physicalCity);

          const physicalState = fieldManager.getField("PhysicalState");
          physicalState.setValue(savedValues.physicalState);

          const physicalZip = fieldManager.getField("PhysicalZip");
          physicalZip.setValue(savedValues.physicalZip);

          const mailingAddressSame =
            fieldManager.getField("MailingAddressSame");
          mailingAddressSame.setValue(savedValues.mailingAddressSame);

          const mailingAddress1 = fieldManager.getField("MailingAddress1");
          mailingAddress1.setValue(savedValues.mailingAddress1);

          const mailingAddress2 = fieldManager.getField("MailingAddress2");
          mailingAddress2.setValue(savedValues.mailingAddress2);

          const mailingCity = fieldManager.getField("MailingCity");
          mailingCity.setValue(savedValues.mailingCity);

          const mailingState = fieldManager.getField("MailingState");
          mailingState.setValue(savedValues.mailingState);

          const licenseNumber = fieldManager.getField("LicenseNumber");
          licenseNumber.setValue(savedValues.licenseNumber);

          const licenseState = fieldManager.getField("LicenseState");
          licenseState.setValue(savedValues.licenseState);

          const licenseExpirationMonth = fieldManager.getField(
            "LicenseExpirationMonth"
          );
          licenseExpirationMonth.setValue(savedValues.licenseExpirationMonth);

          const licenseExpirationDay = fieldManager.getField(
            "LicenseExpirationDay"
          );
          licenseExpirationDay.setValue(savedValues.licenseExpirationDay);

          const licenseExpirationYear = fieldManager.getField(
            "LicenseExpirationYear"
          );
          licenseExpirationYear.setValue(savedValues.licenseExpirationYear);

          const dobMonth = fieldManager.getField("DOBMonth");
          dobMonth.setValue(savedValues.dobMonth);

          const dobDay = fieldManager.getField("DOBDay");
          dobDay.setValue(savedValues.dobDay);

          const dobYear = fieldManager.getField("DOBYear");
          dobYear.setValue(savedValues.dobYear);

          const ssn = fieldManager.getField("SSN");
          ssn.setValue(savedValues.ssn);

          const hairColor = fieldManager.getField("HairColor");
          hairColor.setValue(savedValues.hairColor);

          const eyeColor = fieldManager.getField("EyeColor");
          eyeColor.setValue(savedValues.eyeColor);

          const heightFeet = fieldManager.getField("HeightFeet");
          heightFeet.setValue(savedValues.heightFeet);

          const heightInches = fieldManager.getField("HeightInches");
          heightInches.setValue(savedValues.heightInches);

          const weight = fieldManager.getField("Weight");
          weight.setValue(savedValues.weight);

          const gender = fieldManager.getField("Gender");
          gender.setValue(savedValues.gender);

          const emergencyContactFirstName = fieldManager.getField(
            "EmergencyContactFirstName"
          );
          emergencyContactFirstName.setValue(
            savedValues.emergencyContactFirstName
          );

          const emergencyContactLastName = fieldManager.getField(
            "EmergencyContactLastName"
          );
          emergencyContactLastName.setValue(
            savedValues.emergencyContactLastName
          );

          const emergencyContactPhone = fieldManager.getField(
            "EmergencyContactPhone"
          );
          emergencyContactPhone.setValue(savedValues.emergencyContactPhone);

          const emergencyContactPhoneExtension = fieldManager.getField(
            "EmergencyContactPhoneExtension"
          );
          emergencyContactPhoneExtension.setValue(
            savedValues.emergencyContactPhoneExtension
          );

          const emergencyContactRelation = fieldManager.getField(
            "EmergencyContactRelation"
          );
          emergencyContactRelation.setValue(
            savedValues.emergencyContactRelation
          );
        });
      });
    });
  }, []);

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <div
          className="webviewer"
          ref={viewer}
          style={{ height: "100vh" }}
        ></div>
      </div>
      <div style={{ textAlign: "right", paddingTop: "10px" }}>
        <hr />
      </div>
    </>
  );
}
